.activeStep {
    background-color: black;
    color: white;
  }
  
  .inactiveStep {
    background-color: #f0f0f0;
    color: black;
  }
  
  .activeStep,
  .inactiveStep {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
  
  .grow {
    flex-grow: 1;
  }

  .stepind {
    display: flex;
    flex-direction: column;
  }
  
  
  @media screen and (min-width: 1240px) and (max-width: 1280px){

    .stepNumber {
      height: 50px; /* Adjust as needed */
      width: 50px; /* Adjust as needed */
      font-size: 1.5rem; /* Adjust as needed */
    }

    .description{
      font-size: 1rem;
    }
  }
  

  @media screen and (min-width: 1101px) and (max-width: 1239px) {

    .description{
      font-size: 14px;
      margin-left: 3rem
    }
  }



  
  @media only screen and (min-width: 1025px) and (max-width: 1100px) {

    .description{
      font-size: 13px;
      margin-left: 2rem;
    }

    .stepNumber{
      height:50px;
      width:50px;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    .stepind {
      display: grid;
      grid-template-rows: repeat(2, auto); /* Two rows */
      grid-template-columns: 1fr 1fr; /* Two columns */
      padding-right: 30px;
    }

    .description{
      font-size: 10px;
      margin-top: 70px;
      margin-left: 0px;
      padding-right: 20px;
    }

    .stepNumber{
      height: 100%; /* Adjust as needed */
      width: 10%; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      margin-left: 30px;
    }

    .activeStep,
    .inactiveStep {
      display: grid;
    }
  
}


