
input[type='radio'] {
    height: 20px;
    width: 20px;
    background-color: #ff0000;
    border: 1px solid #ff0000; 
  }
  
  .zeroManual{
    padding-left: 3%;
    padding-right: 3%;
  }
  
  