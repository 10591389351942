.container{
    display: flex;
    justify-content: start;
    align-items: start;
    margin: auto;
    font-size: 20px;
}

.containerText{
    list-style-type: disc;
    margin-top: 2rem;
    line-height: 2rem;
}

.accept{
    height: 15px;
    margin-top: 0.3rem;
}

.disclaimer{
    padding-top: 0 ;
}

