.checkbox {
    /* Increase the size of the checkboxes */
    transform: scale(1);
    margin-right: 8px; /* Adjust the margin as needed */
  }
  
  .checkRadio {
    /* Increase the size of the checkboxes */
    transform: scale(1);
    margin-right: 8px; /* Adjust the margin as needed */
  }
  