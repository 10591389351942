.button {
    border: 1px solid black;
    border-radius: 4px;
    white-space: nowrap;
  }
  
  .skipButton {
    --bgColor: white;
    --textColor: black;
    padding: 16.5px 2px  /* Adjust padding for size */
  }
  
  .saveButton {
    --bgColor: black;
    --textColor: white;
    padding: 8px 16px; /* Adjust padding for size */
  }
  
  
  /* input field */
  
  .inputLabel {
    background-color: var(--inputFieldColor);
    font-size: 18px;
  }
  
  
  
  
    /* dropdown menu  */
    .select-wrapper {
      position: relative;
    }
    
    .select-wrapper::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px; /* Adjust as needed */
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px; /* Adjust the size of the arrow */
      border-color: #000 transparent transparent transparent; /* Adjust the color of the arrow */
    }
  
  
  
    @media screen and (max-width: 1260px){
      nav .step-indicator > div {
        font-size: 2rem; /* Adjust the font size as needed */
      }
    }
    

    @media screen and (max-width: 1180px) and (min-width : 768px){
       #stepInd {
        margin-right: 500px;
      }
    }