
.smallHeader {
    font-size: 4rem; /* Adjust the font size as needed */
    font-family: "Jacques Francois", serif;
  }
  
  .largeHeader {
    font-size: 5rem; /* Adjust the font size as needed */
    font-family: "Jacques Francois", serif;
  }

  @media screen and (max-width: 640px) {
   
    .smallHeader {
      font-size: 3.5rem; /* Adjust the font size as needed */
      font-family: "Jacques Francois", serif;
      margin-bottom: -2px;
    }

    .largeHeader {
      font-size: 2.5rem; /* Adjust the font size as needed */
      font-family: "Jacques Francois", serif;

    }

  }
  